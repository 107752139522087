<template>
	<div>
		<p class="text-center lg:text-left font-semibold text-gray-800 text-sm w-full mb-5 dark:text-neutral-400">{{ t('label.works_with') }}:</p>
		<div class="flex justify-center md:justify-start flex-wrap gap-4 md:gap-8">
			<a href="https://strava.com/" rel="noopener" target="_blank" title="Strava" class="flex items-center">
				<cdn-image class="h-10" :ratio="199/86" src="/logos/strava-compatible-stack-logo.svg" alt="Strava"/>
			</a>
			<a href="https://connect.garmin.com/" rel="noopener" target="_blank" title="Garmin Connect" class="flex items-center">
				<cdn-image :size="{height: 34}" :ratio="1605/365" src="/logos/garmin-compatible-logo.png" alt="Garmin Connect"/>
			</a>
			<a href="https://www.suunto.com/" rel="noopener" target="_blank" title="Suunto" class="flex items-center rounded-lg px-2 py-1 bg-white dark:bg-neutral-200">
				<cdn-image :size="{height: 30}" :ratio="702/250" src="/logos/suunto-compatible-logo.png" alt="Suunto"/>
			</a>
		</div>
	</div>
</template>

<script setup lang="ts">
const {t} = useI18n();
</script>